import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Elements } from "react-stripe-elements";

import CrrReportCheckout from "./modules/crr-report";
import CancelSubscription from "./modules/cancel-subscription";
import Error404 from "./global-components/errors/error-404";

class App extends React.Component {
  constructor(props) {
    super(props);

    let customer = {};

    const search = new URLSearchParams(window.location.search);

    // customer = {
    //   companyId:
    //     parseInt(atob(decodeURIComponent(search.get("companyId")))) || null,
    //   companyName: search.get("companyName") || null,
    //   email: search.get("email") || null,
    //   phone: search.get("phone") || null,
    //   name: search.get("name") || null
    // };

    // ====================================================
    // TEMPORARY. SHOULD BE PERMENANTLY SET TO COMPANYID INSTEAD OF SUPPLIERID FOR SIMPLICITY,
    // BUT EDGE4HEALTH ARE CURRENTLY USING SUPPLIERID
    // ====================================================
    // This is temporary: virtualstock are currently passing supplierId instead
    // of companyId. The above should be used after VS change to companyId
    let companyId;
    if (search.get("supplierId")) {
      companyId = "supplierId";
    } else {
      companyId = "companyId";
    }

    let companyName;
    if (search.get("supplierName")) {
      companyName = "supplierName";
    } else {
      companyName = "companyName";
    }

    customer = {
      companyId:
        parseInt(atob(decodeURIComponent(search.get(companyId)))) || null,
      companyName: search.get(companyName) || null,
      email: search.get("email") || null,
      phone: search.get("phone") || null,
      name: search.get("name") || null
    };
    // ====================================================

    this.state = {
      customer: customer
    };
  }

  render() {
    const { customer } = this.state;

    return (
      <span></span>
    )
  }
}

export default App;
